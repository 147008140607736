/* Homepage.css */
@import "~react-image-gallery/styles/css/image-gallery.css";

.homepage {
	color: white; /* Set font color to white */
	text-align: center;
}  

.carousel-container {
    width: 100%; /* Take the full width of the parent container */
    height: 100%; 
	display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
}
h1 {
	font-size: 55px;
}

.backgroundDiv {
	background-image: url('/src/Images/Work/cleaningBackground.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.sectionDiv {
	overflow: hidden; /* Clear float */
	background-color: #0C3E4A;
	padding: 10px;
  }
  
  .content {
	float: left; /* Float content to the left */
	width: 60%; /* Adjust as needed */
  }
  .content p {
	font-size: 25px; /* Set your desired font size */
  }
  .image-container {
	float: right; /* Float image to the right */
	width: 40%; /* Adjust as needed */
  }
  
  #portrait {
	width: 100%; /* Make the image fill its container */
	max-width: 400px; /* Set your desired max width */
	height: auto; /* Maintain aspect ratio */
	border-radius: 50%; /* Make the image round */
	border: 5px solid #dba009; /* Add a 5px solid border with color #ccc */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow effect */
  }

  #QRcode {
	width: 100%;
	max-width: 150px;
	height: auto;
  }
