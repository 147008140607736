/* Navbar.css */
.navbar {
	display: flex;
	align-items: center;
	background-color: #012730; /* Change this color to your preferred background color */
	padding: 10px 20px;
  }
  
  .logo img {
	max-width: 100px; /* Adjust the width of the logo as needed */
  }
  
  .nav-links {
	list-style: none;
	display: flex;
	font-size: 30px;
	/*margin-left: auto; /* Align links to the left */
  }
  
  .nav-links li {
	margin-right: 20px;
  }
  
  .nav-links li:last-child {
	margin-right: 0;
  }
  
  .nav-links li a {
	color: white; /* Change this color to your preferred text color */
	text-decoration: none;
  }
  
  .nav-links li a:hover {
	text-decoration: underline;
  }
  