/* ContactPage.css */
.contact-page {
	display: flex;
	justify-content: space-between;
	color: white;
  }
  
  .large-textarea {
    width: 100%; /* Full width of the parent container */
    height: 200px; /* Height of the textarea */
    padding: 10px; /* Padding inside the textarea */
    font-size: 16px; /* Adjust the font size if needed */
    border: 1px solid #ccc; /* Border styling */
    border-radius: 4px; /* Rounded corners */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


  .contact-form {
	width: 45%;
  }
  
  .contact-form form {
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 5px;
  }
  
  .contact-form h1 {
	margin-bottom: 10px;
  }
  
  .form-group {
	margin-bottom: 15px;
  }
  
  .form-group label {
	display: block;
	margin-bottom: 5px;
	color: black;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="tel"] {
	width: 100%;
	padding: 8px;
	border-radius: 3px;
	border: 1px solid #ccc;
  }
  
  .form-group input[type="submit"] {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 3px;
	cursor: pointer;
  }
  
  .business-info {
	width: 45%;
	background-color: #0C3E4A;
	margin-top: 20px;
	display: flex;
    flex-direction: column;
	padding-left: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
  }
  
  .business-info h1 {
	margin-bottom: 10px;
  }
  
  .business-info p {
	margin-bottom: 5px;
  }
  